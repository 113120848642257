(function ($) {
  Drupal.behaviors.headerGnavFormatterV1 = {
    attach: function (context) {
      var $body = $('body');
      var $staticHeader = $('#top_wrapper', context);
      var $topHeader = $('.gnav-header-formatter__top', context);
      var bottomOfHeader = $staticHeader.height() + $topHeader.height();
      var $gnav = $('.gnav-header-formatter__gnav', context);
      var $module = $('.js-gnav-header-formatter-v1', context);
      var $shopAll = $('.js-gnav-header-formatter__shop-all', $module);
      var $hamburgerIcon = $('.js-gnav-header-formatter__hamburger', $module);
      var $navigationContainer = $('.js-gnav-header-formatter__sections-panel', $module);
      var $pcLinksBar = $('.js-gnav-header-formatter__sections--pc', $module);
      var $closeIcon = $('.js-gnav-header-formatter__close', $module);
      var $overlay = $('.js-gnav-header-formatter__bg-full-width', $module);
      var $linkWrapper = $('.js-gnav-header-formatter__links', $module);
      var $gnavLink = $(
        '.js-header-gnav-section__link--trigger, .js-header-gnav-section__link--direct',
        $navigationContainer
      );
      var pclinksCount = parseInt($pcLinksBar.data('pc-links'));
      var $firstFivePC = $gnavLink.slice(0, pclinksCount);
      var $gnavSection = $('.header-gnav-section', $module);
      var $pageContent = $('.wrap', context).first();
      var $bottomLinks = $('.gnav-header-formatter__bottom-links', $module);
      var $accountLinkSection = $(
        '.header-gnav-account-link__inner .js-header-gnav-account-link__section',
        $module
      );
      var $accountLinkSectionContainer = $('.gnav-header-formatter__account', $module);
      var isMobile = $body.hasClass('device-mobile');
      var $loyalitySticky = $('#loyalty_market_sticky_nav', context);
      var $headerGnavAccountLinkWrapper = $('.header-gnav-account-link__wrapper', $module);
      // @todo SDSEARCH-2965 - replace with event triggered from React side for Search GNAV manipulation instead of accessing component directly (such as auto close)
      var $searchOverlay = $('.js-sd-search-gnav-input-field', context);

      // Create top-level PC only links
      if ($firstFivePC.length && $pcLinksBar.length) {
        $pcLinksBar.html($firstFivePC.clone());
      }

      // close the nav.
      function closeGnav() {
        $navigationContainer.removeClass('visible expanded');
        $linkWrapper.removeClass('show-subpanel');
        $hamburgerIcon.addClass('visible');
        $gnavSection.attr('aria-expanded', 'false');
        $body.removeClass('active-gnav');
        $gnavLink.removeClass('active disabled');
        if (isMobile && $accountLinkSection.length && $bottomLinks.length) {
          $accountLinkSectionContainer.insertAfter($bottomLinks);
        }
      }

      Unison.on('change', function () {
        closeGnav();
      });

      $(window)
        .off('scroll')
        .on(
          'scroll',
          _.debounce(function () {
            var stickyNavActive = $(window).scrollTop() > bottomOfHeader;
            var $rightStickyColumn = $('.elc-right-sticky-column', context);

            if (stickyNavActive === true) {
              $pageContent.css('padding-top', $gnav.height());
              $rightStickyColumn.css('padding-top', $gnav.height());
              $body.addClass('sticky-nav-active');
              $body.css('margin-top', $topHeader.height() + 'px');
              $loyalitySticky.addClass('fixed');
            } else {
              $pageContent.css('padding-top', 0);
              $rightStickyColumn.css('padding-top', 0);
              $body.removeClass('sticky-nav-active');
              $body.css('margin-top', '0');
              $loyalitySticky.removeClass('fixed');
            }
          }, 5)
        );

      $shopAll.on('click', function (event) {
        event.preventDefault();
        var $openedSearchOverlaySelector = $('.js-search-active-screen-wrapper', context);

        if ($openedSearchOverlaySelector.length) {
          // close search overlay
          $searchOverlay.find('.js-search-close-icon-wrapper').trigger('click');
        }
        $body.addClass('active-gnav');
        $navigationContainer.addClass('visible');
      });

      $hamburgerIcon.on('click', function (event) {
        event.preventDefault();
        $body.addClass('active-gnav');
        $navigationContainer.addClass('visible');
        $hamburgerIcon.removeClass('visible');
      });

      $gnavLink.once().on('click', function () {
        var $this = $(this);
        var $parent = $this.parents('.header-gnav-section');
        var expand = $parent.attr('aria-expanded') === 'false' ? true : false;

        $gnavSection.attr('aria-expanded', 'false');
        if (expand) {
          $navigationContainer.addClass('expanded');
        } else {
          $navigationContainer.removeClass('expanded');
        }
        if ($this.hasClass('active')) {
          $this.removeClass('active');
          $linkWrapper.removeClass('show-subpanel');
          $gnavSection.removeClass('disabled current');
          $gnavSection.attr('aria-expanded', 'false');
          if (
            isMobile &&
            $accountLinkSection.length &&
            $parent.hasClass('js-header-gnav-account-link__section') &&
            $bottomLinks.length
          ) {
            $accountLinkSectionContainer.insertAfter($bottomLinks);
            $headerGnavAccountLinkWrapper.removeClass('gnav-account-link-enable');
          }
        } else {
          $gnavLink.removeClass('active');
          $this.addClass('active');
          $linkWrapper.addClass('show-subpanel');
          $parent.attr('aria-expanded', expand);
          if (
            isMobile &&
            $accountLinkSection.length &&
            $parent.hasClass('js-header-gnav-account-link__section') &&
            $bottomLinks.length
          ) {
            $bottomLinks.insertAfter($accountLinkSectionContainer);
            $headerGnavAccountLinkWrapper.addClass('gnav-account-link-enable');
          }
        }
      });

      if ($firstFivePC.length && $pcLinksBar.length) {
        var $openPanel = $pcLinksBar.find('.js-header-gnav-section__link--trigger');

        $openPanel.once().on('click', function (event) {
          event.preventDefault();
          var $this = $(this);
          var $linkIndex = $this.index();

          $body.addClass('active-gnav');
          $navigationContainer.addClass('visible');
          $gnavLink.eq($linkIndex).trigger('click');
        });
      }

      $closeIcon.once().on('click', function (event) {
        event.preventDefault();
        closeGnav();
      });
      $overlay.on('click', function () {
        closeGnav();
      });

      $gnavSection.hover(
        function () {
          var $this = $(this);

          $gnavSection.addClass('disabled');
          $this.removeClass('disabled').addClass('current');
        },
        function () {
          $gnavSection.removeClass('disabled current');
        }
      );
    }
  };

  $(document).on('navigation.close', function () {
    var $module = $('.js-gnav-header-formatter-v1', document);
    var $closeIcon = $('.js-gnav-header-formatter__close', $module);

    $closeIcon.trigger('click');
  });
})(jQuery);
